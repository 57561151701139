<template>
  <div id='vip' v-loading="pageLoading">
    <pg-nav v-if="Layout.isPc" title="VIP"></pg-nav>
    <scrollbar>
      <div class="vip-body">
        <div class="vip-wrap row row-sb">
          <div class="row column-c">
            <div class="vip-bg">
              <span class="level-text" :data-text="userInfo.level">{{ userInfo.level }}</span>
            </div>
            <div class="vip-info">
              <div>
                <span class="lighten-color">{{ $t('to_level') }}</span>
                <i class="error-color">VIP{{ userInfo.level + 1 }}</i>
              </div>
              <div>
                <span class="lighten-color" v-if="up_type">{{ $t('bdl') }}</span>
                <span class="lighten-color" v-else>{{ $t('bsl') }}</span>
                <strong>{{ (levelTotal - userTotal).toFixed(2) }}</strong>
              </div>
            </div>
          </div>
          <div class="btns">
            <Pg-button :disabled="true" size="mini">{{ $t('Claim_All') }}</Pg-button>
            <Pg-button type="primary" size="mini">{{ $t('History') }}</Pg-button>
          </div>
        </div>
        <div class="vip-scroll-wrap">
          <div class="vip-s-title">{{ $t('VIP_Level_List') }}</div>
          <div class="tabs-wrap">
            <div class="tabs">
              <div class="scroll-tabs">
                <div class="tabs-item" v-for="(item, index) in tabs" :class="{ active: current === index }" :key="index"
                  @click="current = index">{{ item.name }}</div>
              </div>
            </div>
          </div>

          <div class="vip-scroll-body">
            <pg-table :config="tableConfig" :data="tableData">
              <template #level="scope">
                <div class="vip-bg" :class="['level_' + scope.data.level]">
                  <span class="level-text" :data-text="scope.data.level">{{ scope.data.level }}</span>
                </div>
              </template>
              <template #progress="scope">
                <div>
                  {{  up_type?scope.data.deposit_money:scope.data.bet_money }}
                  <Pg-progress :total="Number( up_type?scope.data.deposit_money:scope.data.bet_money)" :current="currentProgress"
                    v-if="scope.data.level == userInfo.level + 1" />
                </div>
              </template>
              <template #bonus="scope">
                <span class="warn-color" v-if="current === 0">{{ scope.data.level_bonus }}</span>
                <span class="warn-color" v-else-if="current === 1">{{ scope.data.week_bonus }}</span>
                <span class="warn-color" v-else>{{ scope.data.month_bonus }}</span>
              </template>
              <!--template #needHeader="scope">
                <div>Upgrade Needs Wager <span class="tip-wrap" @click="tipVisible = true">
                    <Icon type='tip'></Icon>
                  </span></div>
              </template-->
            </pg-table>
          </div>
          <div class="rule-wrap">
            <div class="rule">{{ $t('vip_rule_title') }}</div>
            <div class="rule-content">{{ $t('vip_rule') }}</div>
          </div>
        </div>
      </div>
    </scrollbar>
    <pg-poup v-model="tipVisible">
      <div class="tip-pop">
        <div class="title darken-color">{{ $t('Tips') }}</div>
        <div class="close" @click="tipVisible=false">
              <Icon type='close-line' />
        </div>
        <div class="tip-content">{{ $t('tips_content') }}</div>
      </div>
    </pg-poup>
  </div>
</template>

<script>
import { getVips } from '@/api'
import { mapState } from 'vuex'
export default {
  inject:['Layout'],
  data() {
    return {
      tabs: [
        { name: this.$t('Promotion_Bonus') },
        { name: this.$t('Weekly_Bonus') },
        { name: this.$t('Monthly_Bonus') }
      ],
      current: 0,
      tableData: [],
      userVipInfo: {
        total_bet: 0,
        total_deposit: 0
      },
      pageLoading: false,
      tipVisible: false,
      up_type: 0
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    }),

    levelTotal(){
      const result = this.userInfo.up_type?this.userInfo.vip_level_deposit:this.userInfo.vip_level_bet;
      return result || 0;
    },
    userTotal(){
      const res = this.userInfo.up_type?this.userInfo.total_deposit:this.userInfo.total_bet;
      return res || 0;
    },
    currentProgress(){
      const current = this.up_type?this.userVipInfo.total_deposit:this.userVipInfo.total_bet;
      return isNaN(current)?0:current;
    },
    tableConfig() {
      let config = [
        { label: this.$t('Level'), width: '1rem', type: 'slot', prop: 'level', align: 'center' },
        { label: this.up_type?this.$t('Upgrade_Needs_recharge'):this.$t('Upgrade_Needs_Wager'), headeSlot: 'needHeader', type: 'slot', prop: 'progress', align: 'center' },
        { label: this.$t('Bonus'), type: 'slot', prop: 'bonus', align: 'center' }]
      if (this.current == 1 || this.current == 2) {
        config = [
          { label: this.$t('Level'), width: '1rem', type: 'slot', prop: 'level', align: 'center' },
          { label: this.$t('Bonus'), type: 'slot', prop: 'bonus', align: 'center' }]
      }
      return config
    }
  },
  created() {
    this.getVipInfo()
  },
  methods: {
    getVipInfo() {
      this.pageLoading = true;
      getVips().then(res => {
        console.log(res,'vipInfo')
        this.pageLoading = false;
        this.tableData = res.data.levels;
        this.userVipInfo.member_level = res.data.member_level;
        this.userVipInfo.total_deposit = res.data.total_deposit;
        this.userVipInfo.total_bet = res.data.total_bet;
        this.up_type = res.data.up_type;
      })
    }
  },
}
</script>

<style lang='scss' scoped>
#vip {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .vip-body {
    padding: .2rem;
  }

  .vip-wrap {
    padding: 0.2rem;
    display: flex;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.12rem;

    .btns {
      font-size: 0;
      display: flex;
      flex-direction: column;

      .pg-button {
        padding: 0;
        text-align: center;
        width: 1rem;
        white-space: normal;
        word-break: keep-all;
        line-height: 0.22rem;

        &:nth-child(2) {
          margin-top: .2rem;
        }
      }
    }

    .vip-bg {
      width: 1rem;
      height: 1rem;
      margin: .2rem .2rem 0 0;
    }

    .vip-info {
      font-size: .24rem;
      padding-top: .2rem;

      strong {
        color: var(--theme-text-color-darken);
      }
    }
  }

  .vip-scroll-wrap {
    .vip-s-title {
      font-size: .3rem;
      color: #fff;
      padding-top: .2rem;
    }

    .tabs-wrap {
      padding-bottom: 0.2rem;
    }

    .tabs {
      padding: 0 0.2rem;
      height: 0.54rem;
      overflow: hidden;
      font-size: .2rem;
      border-bottom: .01rem solid var(--theme-color-line);

      .scroll-tabs {
        overflow-x: scroll;
        font-size: 0;
        white-space: nowrap;
        padding-bottom: 0.2rem;

        .tabs-item {
          line-height: 0.54rem;
          display: inline-block;
          margin-right: 0.5rem;
          font-size: .2rem;
          color: var(--theme-text-color-darken);
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 0;
            left: 0;
            right: 0;
            background: var(--theme-primary-color);
            transition: height .3s;
          }

          &.active {
            color: var(--theme-primary-color);

            &::after {
              height: 0.04rem;
            }
          }
        }

      }

    }

    .vip-scroll-body {
      max-height: calc(var(--vh, 1vh) * 100- 5.4rem);;
      overflow-y: scroll;
      font-size: 0.24rem;

      ::v-deep tr {
        height: 1rem;
      }

      .tip-wrap {
        align-items: center;
        background-color: var(--theme-primary-color);
        border-radius: 50%;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        margin-left: 0.02rem;
        width: 0.2rem;
        height: 0.2rem;
        color: var(--theme-primary-font-color);
        font-size: .12rem;
      }

      .vip-bg {
        width: 0.7rem;
        height: 0.7rem;

        .level-text {
          font-size: 0.254545rem;
        }
      }
    }

    .rule-wrap {
      .rule {
        color: var(--theme-text-color-darken);
        font-size: .3rem;
        padding: .2rem .2rem 0;
      }

      .rule-content {
        white-space: pre-line;
        border-top: .01rem solid var(--theme-color-line);
        margin-top: 0.2rem;
        padding: 0.2rem;
        color: var(--theme-text-color);
        font-size: .22rem;
      }
    }

  }

  .tip-pop {
    padding: 0.2rem;
    width: 5.96rem;
    box-sizing: border-box;
    .close{
      display: none;
      position: absolute;
      font-size: 0.2rem;
      top: .2rem;
      right: .35rem;
      color: aliceblue;
    }
    .title {
      font-size: .3rem;
      font-weight: bold;
      text-align: center;
    }

    .tip-content {
      color: var(--theme-text-color);
      font-size: .24rem;
      line-height: 1.5;
      padding: 0.2rem;
      text-align: center;
      margin-top: 0.2rem;
    }
  }
}

@media screen and (min-width:751px) {
  #vip {

    .nav-haeder{
      margin:0 .2rem;
    }
    .tip-pop {
      .close{
        display: flex;
      }
    }
    .vip-scroll-wrap {
      .vip-scroll-body {
        max-height: none;
        overflow-y: hidden;
        padding: 0 0.2rem 0.2rem 0.2rem;
        background-color: var(--theme-main-bg-color);
        border-bottom-left-radius: .1rem;
        border-bottom-right-radius: .1rem;
      }

      .vip-s-title{
        background-color: var(--theme-main-bg-color);
        margin-top: 0.2rem;
        padding: 0.2rem;
        border-top-left-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
      }

      .tabs-wrap{
        background-color: var(--theme-main-bg-color);
      }

      .rule-wrap{
        margin-top: .2rem;
        background-color: var(--theme-main-bg-color);
        border-radius: .1rem;
      }
    }


    .vip-wrap{


      .btns {
        flex-direction: row;
        align-items: center;

        .pg-button {

          &:nth-child(2) {
            margin-top: 0;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
