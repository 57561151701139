<template>
  <transition name="slider-top" @after-leave="$emit('closed')">
    <div class='pg-pop-wrap' v-show="showPop">
      <div class="pop-content">
        <div class="pop-head">
          <span>{{ $t('Tips') }}</span>
        </div>
        <div class="info">
          <div class="info-item">
            <span>{{ $t('vip_level') }}</span>
            <span class="red-color">{{ requestResult.cur_vip }}</span>
          </div>
          <div class="info-item">
            <span>{{ $t('Available_withdrawal_limit') }}</span>
            <span class="red-color">{{ requestResult.can_drawing_amount }}</span>
          </div>
        </div>
       <div class="table-wrap">
         <pg-table :data="requestResult.vip_list" :config="tableConfig">
           <template #amount="scope">
             <span class="warn-color">{{ scope.data.drawing_amount===0?$t('No_limit'):scope.data.drawing_amount}}</span>
           </template>

         </pg-table>

       </div>
        <span class="mobile-el" v-if="showClose">
          <i class="icon-close " @click="handleClose"></i>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showClose: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data(){
    return{
      showPop:false,
      tableConfig: [
        { label: this.$t('vip_level'), prop: 'level', align: 'center' },
        { label: this.$t('Total_recharge'), prop: 'recharge_amount', align: 'center' },
        { label: this.$t('Withdrawal_limit'), prop: 'amount', align: 'center',type:'slot' },
      ],
      requestResult:{
        vip_list:[],
        cur_vip:0,
        can_drawing_amount:0
      },
    }
  },
  methods: {
    show(){
      this.showPop = true;
    },
    handleClose() {
      this.showPop = false;
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-pop-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, .45);


  .pop-content {
    background: var(--theme-bg-color);
    border-radius: 0.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6.8rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
    max-height: calc(100vh - 1rem);
    overflow-y: visible;
    display: inline-flex;
    flex-direction: column;
    border: 0.04rem solid var(--theme-color-line);
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);


    &.dark {

      background-color: var(--theme-main-bg-color);
    }

    &.transparent {
      background-color: transparent;
      border: 1px solid transparent;
    }

    &.full {
      width: 100%;
      max-height: calc(var(--vh, 1vh) * 100);
      height: 100%;
    }

    .pop-head {
      font-size: .3rem;
      text-align: center;
      padding: 0.2rem 0.3rem;
      color: #fff;
      position: relative;

      .close-line {
        cursor: pointer;
        position: absolute;
        right: .1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: .2rem;
        display: inline-block;
        color: var(--theme-text-color);
        height: 0.56rem;
        line-height: .56rem;
        width: 0.56rem;
      }
    }

    .info{
      color: var(--theme-text-color-darken);
      font-size: .22rem;
      .info-item{
        margin-bottom: 0.1rem;
      }
      .red-color{
        margin-left: 0.1rem;
        color: #EA4E3D;
      }
    }
    .table-wrap{
      min-height: 6rem;
      padding-bottom: 0.2rem;
      ::v-deep .pg-container .pg-table .table-tbody tr td{
        font-size: .23rem;
        padding: .15rem 0;
      }
    }
    .icon-close {
      position: absolute;
      display: inline-block;
      width: 0.5984rem;
      height: 0.5984rem;
      left: 50%;
      bottom: -1rem;
      transform: translateX(-50%);
      background-image: var(--bgUrl);
      background-position: -5.1136rem -1.3872rem;
      background-size: 7.072rem 6.5416rem;
    }

  }
}

.pc-page {
  .pg-pop-wrap .pop-content {
    top: calc(50% + 0.3rem);
  }
}
</style>
