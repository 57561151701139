export default {
    Home: "Inicio",
    offers: "ofertas",
    Profile: "Perfil",
    Profile_me_info: "Perfil",
    Withdrawal: "Retiro",
    Customer_service: "Servicio al cliente",
    login: "Iniciar sesión",
    register: "Registrarse",
    deposit: "Depósito",
    withdraw: "Retirar",
    Withdraw_Records: "Registros de retiros",
    Audit_Report: "Informe de auditoría",
    Manage_Account: "Administrar cuenta",
    Balance: "Saldo",
    Normal_withdrawal: "Retiro normal",
    Withdrawal_Password: "Contraseña de retiro",
    Not_Started: "No iniciado",
    Completed: "Completado",
    Ongoing: "En curso",
    interest: "Interés",
    dont_show: "No mostrar de nuevo hoy",
    Casino: "Casino",
    Reward: "Recompensa",
    Agent: "Agente",
    tab_agent: "Agente",
    net_info: "Red de agentes",
    Offer_Center: "Centro de ofertas",
    Event: "Evento",
    APP_download: "Descargar APP",
    Mission: "Misión",
    Games: "Juegos",
    Support: "Soporte",
    Online_Service: "Servicio en línea",
    Help_Center: "Centro de ayuda",
    Message_Center: "Centro de mensajes",
    forgot_password: "Olvidé mi contraseña",
    Remmeber_Me: "Recordarme",
    Register_An_Account: "Registrar una cuenta",
    Username: "Nombre de usuario",
    Name: "Nombre",
    user_tip: "4-16 caracteres, soporta inglés/números",
    password: "Contraseña",
    password_tip: "6-16 caracteres",
    password_agin_tip: "Las contraseñas no coinciden. Por favor, reintroduce.",
    Re_enter_password: "Reintroducir contraseña",
    over_tip: "Tengo más de 18 años, he leído y estoy de acuerdo con el",
    User_Agreement: "Acuerdo del usuario",
    I_have_read: "He leído",
    user_agreement_1: `1. Para evitar disputas de apuestas, los miembros deben leer las reglas de la empresa antes de ingresar a la aplicación. Una vez que el jugador "Estoy de acuerdo" Al ingresar a esta empresa para apostar, se considerará que está de acuerdo con el Acuerdo del usuario de la empresa. `,
    user_agreement_2: `2. Es responsabilidad del miembro asegurarse de la confidencialidad de su cuenta e información de inicio de sesión. Cualquier apuesta en línea realizada con su número de cuenta y contraseña de miembro se considerará válida. Cambie su contraseña de vez en cuando. La empresa no tiene responsabilidad por ninguna compensación de apuestas realizadas con una cuenta y contraseña robadas.`,
    user_agreement_3: `La empresa se reserva el derecho de modificar este acuerdo o las reglas del juego o las reglas de la confidencialidad de vez en cuando. Los términos modificados entrarán en vigencia en la fecha especificada después de que ocurra el cambio, y se reservan los derechos finales para tomar decisiones sobre todos los conflictos para la empresa.`,
    user_agreement_4: `4. Los usuarios deben ser mayores de edad según las leyes del país de residencia para usar el casino o la aplicación en línea. Las apuestas en línea que no se hayan enviado exitosamente se considerarán nulas.`,
    user_agreement_5: `5. Cuando un jugador se desconecta automáticamente o forzosamente del juego antes de que se divulgue el resultado del juego, esto no afectará el resultado del juego.`,
    Messages: "Mensajes",
    Account: "Cuenta",
    Main_Wallet: "Billetera principal",
    To_achieve_next_level: "Para alcanzar el siguiente nivel",
    Betting_still_lacks: "Aún faltan apuestas",
    Upgrade_Needs_Wager: "Necesita apuestas para ascender",
    Upgrade_Needs_recharge: "Actualización necesita recarga de",
    Account_Details: "Detalles de la cuenta",
    Bet_Records: "Registros de apuestas",
    Report: "Informe",
    Reports: "Informes",
    Withdrawal_Management: "Gestión de retiros",
    Withdrawal_Method: "Método de retiro",
    million_monthly: "millón mensual",
    Security_Center: "Centro de seguridad",
    Language: "Idioma",
    Feedback_to_get_rewards: "Dar retroalimentación para obtener recompensas",
    Quit: "Salir",
    Login_Now: "Iniciar sesión ahora",
    First_deposit_upon_registration: "Primer depósito al registrarse",
    Bonus: "Bonificación",
    Collected: "Recogido",
    Task_time: "Tiempo de tarea",
    bonus_1: "Tarea a largo plazo (cada nueva cuenta solo puede participar una vez)",
    bonus_2: "II.Condiciones de la tarea:",
    bonus_2_content: "Completar configuraciones y enlaces de seguridad relacionados con la cuenta",
    bonus_3: "III.Contenido de la tarea:",
    bonus_3_content: `1.Cada nueva cuenta registrada puede completar las tareas anteriores, y al completarlas, recibirá una cierta cantidad de bonificación. Cuanto mayor sea la dificultad, mayor será la recompensa. <br>2.Dado que cada cuenta es completamente anónima, si se roba, resultando en pérdida de fondos, no se podrá recuperar. Por lo tanto, exigimos vincular la verificación en dos pasos, especialmente al agregar direcciones de retiro, para probar que es una operación personal y garantizar su seguridad. <br>3.Condiciones cumplidas para reclamar directamente, y se pueden reclamar directamente en cualquier plataforma; expirarán si no se reclaman (no reclamar se considera renuncia voluntaria). <br>4.Debido a la bonificación relativamente alta de esta tarea, la bonificación requiere 2 veces de auditoría (es decir, auditoría, apuestas, o apuestas válidas) para retirar, sin restricciones en plataformas de juegos. <br>5.Esta tarea es exclusivamente para las operaciones normales manuales del titular de la cuenta; alquilar, usar trampas, bots, apuestas cruzadas, cepillado mutuo, arbitraje, API, protocolos, explotar vulnerabilidades, controles grupales u otros medios técnicos están prohibidos. Las infracciones resultarán en la cancelación o deducción de recompensas, congelación de cuentas o incluso colocación en una lista negra. <br>6.Para evitar diferencias en la comprensión del texto, la plataforma retendrá la interpretación final de este evento.`,
    Accumulated_bets_made: "Apuestas acumuladas",
    Go: "Ir",
    Deposit_Records: "Registros de depósitos",
    Online_Deposit: "Depósito en línea",
    Deposit_Amount: "Cantidad de depósito",
    Bonus_event_explanation: "Explicación del evento de bonificación",
    Deposit_Now: "Depositar ahora",
    The_first_deposit_to_receive: "El primer depósito para recibir",
    Total_Deposit: "Depósito total",
    Deposit_Details: "Detalles del depósito",
    Today: "Hoy",
    Yesterdaty: "Ayer",
    Days: "Días",
    Day: "Día",
    DAY: "DÍA",
    All: "Todo",
    Total_Withdrawal: "Retiro total",
    Total_Withdraw: "Retiro total",
    Accumulated_Claimed_Benefits: "Beneficios acumulados reclamados",
    Transaction_Type: "Tipo de transacción",
    Action: "Acción",
    Deposit_Method: "Método de depósito",
    Deposit_channels: "Canales de depósito",
    Time_Created: "Fecha de creación",
    Order_No: "Número de orden",
    Newbie_Bonus: "Bonificación para principiantes",
    Daily_Mission: "Misión diaria",
    Claim_All: "Reclamar todo",
    History: "Historial",
    Rebate: "Reembolso",
    Claim: "Reclamar",
    Claim_1: "Reclamar",
    Claimed: "Reclamado",
    Next: "Siguiente",
    No_Records: "Sin registros",
    Level: "Nivel",
    Valid_Bets: "Apuestas válidas",
    First_Deposit: "Primer depósito",
    Online_login_and_counts: 'Desconectado',
    Online_login_and_counts_pc: "Inicio de sesión en línea y conteo",
    Commission: "Comisión",
    Promotion_Sharing: "Compartir promociones",
    My_Data: "Mis datos",
    Agent_Network: "Red de agentes",
    Referral_Link: "Enlace de referencia",
    Performance: "Rendimiento",
    Subordinate_s_Wagers: "Apuestas del subordinado",
    All_data: "Todos los datos",
    Subordinate_stats: "Estadísticas de subordinados",
    Agent_Bonus_Rate: "Tasa de bonificación de agente",
    Collectable: "Recogible",
    Agent_Tier: "Nivel de agente",
    Promotion_conditions: "Condiciones de promoción (Rendimiento acumulado)",
    Total_Pending_Audit: "Total pendiente de auditoría",
    Audit_Status: "Estado de auditoría",
    Funds_Change: "Cambio de fondos",
    Number_of_audits: "Número de auditorías",
    Total_Audit_Required: "Auditorías totales requeridas",
    Pending_audit: "Pendiente de auditoría",
    Audited: "Auditorizado",
    Limited_to_the_platform: "Limitado a la plataforma",
    Game_Limited: "Juego(s) limitado(s)",
    Total_Bet_Lucky_Spin: "Apuesta total de giros afortunados",
    My_Link: "Mi enlace",
    Quick_Share: "Compartir rápidamente",
    valid_s: "Subordinados válidos",
    people: " personas",
    Details: "Detalles",
    box_tip: "¿Qué es un número válido de referencias? (Cumpliendo simultáneamente las siguientes condiciones)",
    the_s: "El depósito total del subordinado",
    the_turnover: "El volumen total de negociación del subordinado",
    Or_the_above: "O lo anterior",
    Create: "Crear",
    Mine: "Mío",
    Feedback_Content: "Contenido de la retroalimentación",
    Suggestions_for_revision: "Sugerencias para revisión",
    Attachment: "Adjunto",
    Easier_to_be_adopted: "Más fácil de adoptar",
    Reupload: "Volver a cargar",
    upload_tip: "Soporta formato de imagen y video, tamaño no debe exceder 50MB",
    Reward_Rules: "Reglas de recompensas",
    Reward_Rules_content: "Hemos establecido una gran bonificación para recopilar retroalimentación específicamente para optimizar nuestro sistema y características para una mejor experiencia para ti. Una vez adoptada, las recompensas se otorgarán según la importancia (excluyendo las no adoptadas).",
    Submit_feedback: "Enviar retroalimentación",
    No_Messages: "Sin mensajes",
    online_tip: "Profesional servicio al cliente a tiempo completo siempre en línea para ayudarte con cualquier pregunta.",
    plat_sup: "Soporte",
    News: "Noticias",
    Notification: "Notificación",
    Marquee: "Cinta desplazable",
    daily_i_1: "Ingresado continuamente durante",
    daily_i_2: "días",
    Required_deposit: "Depósito requerido",
    Required_bets: "Apuestas requeridas",
    daily_rule_title_1: "I.Requisito del evento:",
    daily_rule_content_1: "Depósito + Apuestas (se reinicia después de 7 días consecutivos)",
    daily_rule_title_2: "II.Contenido del evento:",
    daily_rule_content_2: `1.Se requieren depósitos y apuestas diarias que cumplan con los requisitos del evento para un ingreso exitoso y reclamar bonificaciones fijas o misteriosas, hasta 19. <br>
  2.Este evento es un evento de ingreso consecutivo (reinicia desde el día 1 en caso de interrupciones); <br>
  3.Las recompensas solo pueden reclamarse manualmente en iOS, Android, H5, PC <br>
  4.El bono otorgado en este evento (excluyendo el principal) requiere 1 vez de rodaje (es decir, verificación, apuestas, o apuestas válidas) para retirar, las apuestas no están limitadas a una plataforma de juego; <br>
  5.Este evento solo es para las operaciones normales manuales del propietario de la cuenta, alquiler, uso de software de trampa, bots, <br>
  apuestas entre diferentes cuentas, manipulación mutua, arbitraje, APIs, protocolos, explotación de vulnerabilidades, control grupal u otros medios técnicos están prohibidos, de lo contrario, las recompensas pueden ser canceladas <br>
  o deducidas, o las cuentas pueden ser congeladas o incluso incluidas en una lista negra; <br>
  6.Para evitar diferencias en la comprensión del texto, la plataforma retendrá la interpretación final de este evento.`,
    Back: "Volver",
    Hot: "Caliente",
    Recent: "Reciente",
    Distributed: "Distribuido",
    Deposited: "Depositado",
    Transfer_in: "Transferencia entrante",
    Transfer_out: "Transferencia saliente",
    Settlement_period: "Período de liquidación",
    hours: "horas",
    Total_claimed: "Total reclamado",
    Interest_ceiling: "Techo de interés",
    No_restrictions: "Sin restricciones",
    Total_Earnings: "Ganancias totales",
    Record_details: "Detalles del registro",
    Interest_rules: "Reglas de interés",
    Interest_tips: "Tasa Anual 15%",
    Time: "Tiempo",
    Type: "Tipo",
    Amount: 'Cantidad',
    Rebate_Records: 'Registros de reembolsos',
    Rebate_rate: "Tasa de reembolso",
    Receive_amount: "Cantidad recibida",
    Rebateable: "Reembolsable",
    to_level: "Para alcanzar el siguiente nivel",
    bsl: "Faltan apuestas",
    bdl: "El recargo aún falta ",
    VIP_Level_List: "Lista de niveles VIP",
    Promotion_Bonus: "Bonificación de promoción",
    Weekly_Bonus: 'Bonificación semanal',
    Monthly_Bonus: 'Bonificación mensual',
    Tips: "Consejos",
    tips_content: `Para avanzar al siguiente nivel, basándose en las apuestas válidas acumuladas existentes, se requieren apuestas adicionales. Por ejemplo, para avanzar a VIP 1, se necesitan 1000 apuestas válidas acumuladas, y para avanzar a VIP 2, se necesitan 2000 apuestas válidas acumuladas. Por lo tanto, para llegar a VIP 2, un jugador necesita un total de 3000 apuestas válidas acumuladas (1000 + 2000), y así sucesivamente.`,
    vip_rule_title: "Explicación de las reglas VIP",
    vip_rule: `1.Promotion standard: Meet the VIP promotion requirements (i.e., both deposit and valid bets meet the criteria) to upgrade to the corresponding VIP level and receive the corresponding promotion bonus. If you consecutively upgrade multiple levels, you can receive all the level promotion bonuses. Real-time bonuses can be claimed; <br>
    2.Daily bonus: By meeting the deposit and valid bet requirements of the current level, you can receive the corresponding daily bonus. If you consecutively upgrade multiple levels, only the daily bonus of the current level can be obtained. Real-time bonuses can be claimed; <br>
    3.Weekly bonus: By meeting the deposit and valid bet requirements of the current level each week, you can receive the corresponding weekly bonus. If you consecutively upgrade multiple levels, only the weekly bonus of the current level can be obtained. Real-time bonuses can be claimed; <br>
    4.Monthly bonus: By meeting the deposit and valid bet requirements of the current level each month, you can receive the corresponding monthly bonus. If you consecutively upgrade multiple levels, only the monthly bonus of the current level can be obtained. Real-time bonuses can be claimed; <br>
    5.Reward expiration time: The received bonus is retained for %d days. If it is not actively claimed during this period, it will be credited automatically to the account. For example: if a reward is obtained on January 1st and retained for %d days, it will be credited automatically to the account at 00:00:00 on %d January. <br>
    6.Maintenance explanation: After reaching a certain VIP level, if the member's deposit and valid bets of the previous month do not meet the maintenance requirements, the level will be automatically downgraded. <br>
    7.Note on maintaining VIP status: To maintain your VIP status, you must meet the monthly deposit and valid bet requirements. If you fail to meet these requirements in any given month, your VIP level will be downgraded accordingly. <br>
    8.Restrictions: The use of cheating software, bots, or any other technical means to manipulate the system is strictly prohibited. If such activities are detected, the rewards may be canceled or deducted, and the accounts may be frozen or even blacklisted. <br>
    9.Final interpretation: The platform retains the final interpretation rights for this VIP program.`,
    Misson: "Misión",
    Set_Up: "Configurar",
    Link: "Enlace",
    Please_link_your_mobile_phone: "Por favor, enlace su número de teléfono",
    Please_link_your_mail: "Por favor, enlace su correo electrónico",
    birth_tip: "Cumpleaños (no se puede modificar una vez establecido)",
    pltwaf: "Por favor, enlace la cuenta de retiro primero",
    ped: 'Ingrese 6-40 dígitos',
    plw: 'Ingrese WhatsApp',
    pefa: "Ingrese cuenta de Facebook",
    pet: 'Ingrese Telegram',
    eut: 'Ingrese hasta 5-40 ',
    Year: "Año",
    Save: "Guardar",
    Save_Btn: "GUARDAR",
    Male: "Masculino",
    Female: "Femenino",
    Statement: "Declaración",
    Cumulative_bet_amount: "Cantidad acumulada de apuestas",
    Total_Valid_Bets: "Apuestas válidas totales",
    Total: "Total W/L",
    nob: "Núm. de apuestas",
    ba: "Importe de apuesta",
    Ganhos: "W/L",
    Verify_Login_Password: "Verificar contraseña de inicio de sesión",
    you_tip: "Puede enlazar el teléfono después de verificar la contraseña de inicio de sesión",
    Other_Verification_Methods: "Otros métodos de verificación",
    The_above_method_is_not_available: "¿El método anterior no está disponible?",
    Contact_Us: "Contáctenos",
    Enter_Password: "Ingrese la contraseña",
    Mobile_Phone: "Teléfono móvil",
    phone: "Ingrese el número de teléfono",
    Player_ID: "ID del jugador",
    Unlinked: "Sin enlazar",
    Email: "Correo electrónico",
    Login_Password: "Contraseña de inicio de sesión",
    Security_Question: "Pregunta de seguridad",
    Link_Third_party_Login: "Enlazar inicio de sesión de terceros",
    Unset: "No definido",
    set_title: "Es su primer retiro, necesita establecer una contraseña de retiro primero",
    set_up: "Configurar contraseña de retiro",
    New_Withdrawal: "Nueva contraseña de retiro",
    Old_Withdrawal: "Contraseña de retiro anterior",
    password_note: `Nota: Su contraseña de retiro protege la seguridad de sus fondos. Es muy importante y
    debe mantenerla en secreto para evitar cualquier pérdida financiera.`,
    confirm: "confirmar",
    Confirm: "Confirmar",
    Current_Lucky_Value: "Valor afortunado actual",
    you_still_need: "Aún necesita apostar",
    to_get: "para obtener",
    Lucky_Points: "Puntos afortunados",
    Instant: "Instantáneo<br>Dibujo",
    Luck_Value: "Valor de suerte",
    Silver: "Plata",
    Golden: "Oro",
    Diamond: "Diamante",
    Reward_announcement: "Anuncio de recompensas",
    My_Records: 'Mis registros',
    won_at: "ganó en",
    returntable_rule_content: "Apuestas válidas acumuladas (es decir, apuestas o auditoría de la plataforma especificada)",
    Designated_platform: "Plataforma designada",
    return_table_content_2: `1.Durante el período del evento, cada apuesta válida genera automáticamente 1 punto afortunado. 1000-10000 puntos afortunados permiten un sorteo, con una recompensa máxima de 9999.<br>
    2.Los puntos afortunados obtenidos hoy y no utilizados al día siguiente caducarán y se restablecerán a cero;<br>
    3.Las recompensas solo pueden reclamarse manualmente en iOS, Android, H5, PC<br>
    4.El bono otorgado en este evento (excluyendo el principal) requiere 1 vez de rodaje (es decir, verificación,
    apuestas, o apuestas válidas) para retirar, las apuestas no están limitadas a una plataforma de juego;<br>
    5.Estos eventos son solo para las operaciones normales manuales del propietario de la cuenta, alquiler, uso de software de trampa,
    bots, apuestas entre diferentes cuentas, manipulación mutua, arbitraje, APIs, protocolos, explotación de vulnerabilidades, control grupal, o otros medios técnicos están prohibidos, de lo contrario las recompensas pueden ser
    canceladas o deducidas, o las cuentas pueden ser congeladas o incluso incluidas en una lista negra;<br>
    6.Para evitar diferencias en la comprensión del texto, la plataforma retendrá la interpretación final
    de este evento.`,
    twde: "Apuestas totales durante el período del evento",
    Used_Lucky_Value: "Valor afortunado utilizado",
    Expired_Lucky_Value: "Valor afortunado caducado",
    Available_Lucky_Value: "Valor afortunado disponible",
    Claim_Successfully: "Reclamado con éxito",
    Total_Account_Balance: "Saldo total de la cuenta",
    One_click_transfer: "Transferencia de un clic",
    Live: "En vivo",
    Fishing: "Pesca",
    Sports: "Deportes",
    Cards: "Cartas",
    Lottery: "Lotería",
    Blockchain: "Juegos de blockchain",
    Slots: "Slots",
    All_Type: "Todos los tipos",
    ALL_Platform: "Todas las plataformas",
    Platform: "Plataforma",
    Total_Commission: "Comisión total",
    Referral_information: "Información de referencia",
    More: "Más",
    Commission_Collected: "Comisión recolectada",
    Last_Commission: "Última comisión",
    Total_Members: "Miembros totales",
    Total_angent: "Total",
    Direct_Subordinates: "Subordinados directos",
    Others: "Otros",
    Total_Perf: "Rendimiento total",
    Sub_Perf: "Rendimiento del subordinado",
    Others_Perf: "Rendimiento de otros",
    Subordinate_Wagers: "Apuestas del subordinado",
    Total_valid_bets: "Apuestas válidas totales",
    total_no: "Total de apuestas",
    Total_angent_wl: "Total W/L",
    Withdrawal_Account: "Cuenta de retiro",
    Hide: "Ocultar",
    Show: "Mostrar",
    Add_New_Bank_Account: "Agregar nueva cuenta bancaria",
    Enter_PIN: "Ingrese PIN",
    for_you_account: "Para su seguridad de cuenta, ingrese la contraseña de retiro",
    pix_tip: "La cuenta PIX no puede estar vacía",
    digits_11: "Ingrese 11 dígitos",
    digits_6: "Ingrese 6 dígitos",
    Set_as_Default: "Establecer como predeterminado",
    Add_withdrawal_account: "Agregar cuenta de retiro",
    add_account_tip: "Por favor, seleccione una cuenta de retiro primero",
    with_tip_1: "La cantidad de apuestas restantes requerida para el retiro es",
    with_tip_2: " ",
    Min: 'Mínimo',
    Max: 'Máximo',
    Withdrawal_Amount_cannot_be_empty: 'La cantidad de retiro no puede estar vacía',
    withdraw_err: "Retiro no disponible hasta que se complete la auditoría",
    wp_can_be_empty: "La contraseña de retiro no puede estar vacía",
    Can_be_collected_today: "Puede ser recolectado hoy",
    Fee: "Tarifa",
    Add: "Agregar",
    All_status: "Todos los estados",
    Not_settled: 'No liquidado',
    Settled: 'Liquidado',
    Order_canceled: "Orden cancelada",
    Confirm_New_Withdrawal_Password: "Confirmar nueva contraseña de retiro",
    Search: "Buscar",
    Search_Games: "Buscar juegos",
    tab_Search: "Buscar",
    Favorite: "Favorito",
    Reports_tab: "Informe",
    em_claim: "Actualmente no hay recompensas disponibles para reclamar",
    Start_Date: "Fecha de inicio",
    End_Date: "Fecha de fin",
    Cancel: "Cancelar",
    My_ID: "Mi ID",
    Contribution_Commission: "Comisión de contribución",
    total_first: "Total de jugadores con primer depósito",
    Other_first_deposit: "Otro primer depósito",
    Other_deposit: "Otro depósito",
    Direct_first_deposit: "Primer depósito directo",
    Subordinate_Deposits: "Depósitos de subordinados",
    Subordinate: "Subordinado",
    Full_Screen: "Pantalla completa",
    Refresh: "Actualizar",
    Yes: "Sí",
    No: "No",
    Settlement_date: "Fecha de liquidación",
    Contributors: "Colaboradores",
    Joining_time: "Tiempo de ingreso",
    Member_ID: "ID del miembro",
    Statistical_date: "Fecha estadística",
    Number_of_bets: "Número de apuestas",
    Total_W_L: "Total W/L",
    bounusTip: "Los miembros de línea inferior solo contribuirán con devoluciones si sus depósitos ≥0 y han realizado apuestas válidas ≥0 durante el período de liquidación.",
    Type_Details: "Detalles del tipo",
    Bet_ID: "ID de apuesta",
    Bet_amount: "Importe de apuesta",
    In_game: "Uso en juego",
    page: 'página',
    Page: 'Página',
    entries: 'entradas',
    page_total_1: "Total de",
    page_total_2: "entradas",
    more_1: "Actualmente mostrando",
    more_2: "Juegos populares de",
    more_3: "",
    Load_More: "Cargar más",
    Reminder: "Recordatorio",
    Log_out_from_account: "Cerrar sesión de la cuenta",
    Cashback_Exclusivo: "Cashback Exclusivo",
    privileges_Exclusivo: "Privilegios VIP",
    login_password_tip: 'Modifique la contraseña de inicio de sesión después de verificar la contraseña de inicio de sesión',
    Confirm_the_new_password: "Confirmar la nueva contraseña",
    Daily_loss_rescue_fund: "Fondo de rescate diario por pérdidas",
    Loss_amount: "Importe de pérdida",
    Extra_Rewards: "Recompensas extras",
    Yesterday_s_losses: "Pérdidas de ayer",
    Today_s_Rescue_Bonus: "Bonificación de rescate de hoy",
    Lucky_wager: "Apuesta afortunada",
    No_winning_wager_today: "No hay apuesta ganadora hoy",
    max_times_claimed: "Máx. {num} veces reclamado hoy ({num} veces restantes)",
    Bet_number_last_digits: "Últimos dígitos del número de apuesta",
    Reward_multiple: "Multiplicador de recompensa",
    Reward_limit: "Límite de recompensa",
    reward_amount: "Importe de recompensa = Importe de apuesta de esa boleta x Multiplicador de bonificación, sin exceder el límite",
    Music: 'Música',
    Cycle: 'Ciclo',
    Shuffle: 'Aleatorio',
    Repeat: 'Repetir',
    Downloaded: 'Descargado',
    System_Music: "Música del sistema",
    My_music: "Mi música",
    change_password_note: "Atención: La contraseña de retiro protege sus fondos y es extremadamente importante. Manténgala en secreto para prevenir cualquier pérdida financiera",
    Change_Withdrawal_Password: 'Cambiar contraseña de retiro',
    Link_Email: 'Enlazar correo electrónico',
    Enter_Email: 'Ingrese correo electrónico',
    Deslize_para: 'Deslice para completar el rompecabezas',
    length_tip: '{lengthRange}dígitos',
    Numbers: 'Números',
    Lowercase: 'Minúsculas',
    Uppercase: 'Mayúsculas',
    My_superiors: 'Mis superiores',
    Agent_mode: 'Modo agente',
    Unlimited_level: 'Diferencia de nivel ilimitada (Liquidación diaria)',
    Me_Header_user: "Datos",
    Me_Header_Support: "Soporte",
    Yesterday: "Ayer",
    This_Week: "Esta semana",
    Last_Week: "Semana pasada",
    This_Month: "Este mes",
    Last_Month: "Mes pasado",
    Add_direct: "Agregar miembros directos",
    deposit_players: "Número de jugadores con primer depósito",
    Number_deposits: "Número de depósitos",
    Deposit_amount: "Importe del depósito",
    Direct_subordinates: "Subordinados diretos",
    RealName: "Nombre real",
    real_name_validate:'4-16 carácter bit, soporte en inglés/números',
    is_required:'es necesario',

    // 399pop
    vip_level: "Nivel de VIP",
    Total_recharge: "Recarga acumulada",
    Withdrawal_limit: "Límite de retiro",
    Current_VIP_level: "Nivel de VIP actual",
    Available_withdrawal_limit: "Límite de retiro disponible",
    No_limit: "Infinito"

};
