import request from "./request";

export function apiHomeBanner() {
    return request({
        url: "/api/banners",
    });
}

export function apiGamesList() {
    return request({
        url: "api/main/games",
    });
}

export function apiRegister(data) {
    return request({
        url: "/api/auth/app/register",
        method: "post",
        data,
    });
}
export function apiImageCode() {
    return request({
        url: "/api/auth/captcha",
        method: "post",
    });
}

export function apiToLogin(data) {
    return request({
        url: "/api/auth/app/login",
        method: "post",
        data,
    });
}

export function apiUserInfo() {
    return request({
        url: "/api/auth/me",
        method: "post",
    });
}

export function apiGameLogin(data) {
    return request({
        url: "/api/game/login",
        method: "post",
        data,
    });
}

export const apiGameListUrl = "/api/game_list";
export function apiGameList(params) {
    return request({
        url: apiGameListUrl,
        method: "get",
        params,
    });
}
export function getLevelRebateList(params) {
    return request({
        url: "/api/agent/level_rebate",
        method: "get",
        params,
    });
}
export function getVips(params) {
    return request({
        url: "/api/member/vips",
        method: "get",
        params,
    });
}
export function apiGetBoxInfo() {
    return request({
        url: "/api/box/info",
        method: "post",
    });
}
export function apiGetMoney() {
    return request({
        url: "/api/game/api_moneys",
        method: "post",
    });
}
export function apiPostGameRecord(data) {
    return request({
        url: "/api/game/record",
        method: "post",
        data,
    });
}
export function apiUpdateUserinfo(data) {
    return request({
        url: "/api/auth/info/update",
        method: "post",
        data,
    });
}
export function apiGetCommission(data) {
    return request({
        url: "/api/agent/sum_commission",
        method: "post",
        data,
    });
}
export function apiGetPaymentsList() {
    return request({
        url: "/api/payments/list",
        method: "get",
    });
}
export function apiPostOnline(data) {
    return request({
        url: "/api/recharge/online",
        method: "post",
        data,
    });
}
export function apiPostFirstReward() {
    return request({
        url: "/api/first_reward/list",
        method: "post",
    });
}
// export function apiPostMessageList() {
//   return request({
//     url: "/api/member/message/list",
//     method: 'post',
//   });
// }
export function apiPostRechargeList(data) {
    return request({
        url: "/api/recharge/list",
        method: "post",
        data,
    });
}
export function apiPostDailySignList() {
    return request({
        url: "/api/daily_sign/list",
        method: "post",
    });
}
export function apiPostDailySignIn() {
    return request({
        url: "/api/daily_sign/sign_in",
        method: "post",
    });
}
export function apiGetAsidesList() {
    return request({
        url: "/api/asides/list",
        method: "get",
    });
}
export function apiGetNotices() {
    return request({
        url: "/api/system/notices",
        method: "get",
    });
}
export function apiGetMoneylog(data) {
    return request({
        url: "/api/moneylog",
        method: "post",
        data,
    });
}
export function apiGetMoneylogType() {
    return request({
        url: "/api/moneylog/type",
        method: "get",
    });
}
export function apiGetSite() {
    return request({
        url: "/api/site_base",
        method: "get",
    });
}
export function apiGetService() {
    return request({
        url: "/api/service",
        method: "get",
    });
}
export function apiAgentTotal() {
    return request({
        url: "/api/agent/total",
        method: "post",
    });
}
export function apiBoxCollect(data) {
    return request({
        url: "/api/box/collect",
        method: "post",
        data,
    });
}
export function apiMissoionInfo() {
    return request({
        url: "/api/mission/list",
        method: "post",
    });
}
export function apiMissoionCollect(data) {
    return request({
        url: "/api/mission/collect",
        method: "post",
        data,
    });
}
export function apiBankList() {
    return request({
        url: "/api/member/bank",
        method: "get",
    });
}
export function apiAddBank(data) {
    return request({
        url: "/api/member/bank",
        method: "post",
        data
    });
}
export function apiPostPassword(data) {
    return request({
        url: "/api/member/verify_qk_pwd",
        method: "post",
        data
    });
}
export function apiSetPassword(data) {
    return request({
        url: "/api/member/drawing_pwd/set",
        method: "post",
        data,
    });
}
export function apiSetDefaultPassword(data) {
    return request({
        url: "/api/member/set_default_bank",
        method: "post",
        data,
    });
}
export function apiDrawingLimit(data) {
    return request({
        url: "/api/drawing/limit",
        method: "get",
        data,
    });
}


export function apiDrawing(data) {
    return request({
        url: "/api/drawing",
        method: "post",
        data,
    });
}

export function apiAuditList(params) {
    return request({
        url: "/api/audit/list",
        method: "get",
        params,
    });
}


export function apiAuditType() {
    return request({
        url: "/api/audit/type",
        method: "get",
    });
}

export function apiAgentAll(data) {
    return request({
        url: "/api/agent/all_data",
        method: "post",
        data
    });
}

export function apiDrawingList(data) {
    return request({
        url: "/api/drawing/list",
        method: "post",
        data
    });
}

export function apiCollectCommission(data) {
    return request({
        url: "/api/agent/collect_commission",
        method: "post",
        data
    });
}
export function apiGetAllTotal(data) {
    return request({
        url: "/api/agent/all_total",
        method: "post",
        data
    });
}
export function apiGetActivities() {
    return request({
        url: "/api/activities",
        method: "get",
    });
}
export function apiGetRuleList(params) {
    return request({
        url: "api/member/getfd_rule",
        method: "get",
        params,
    });
}

export function apiGetGameSearch(params) {
    return request({
        url: "/api/games/search",
        method: "get",
        params
    });
}
export function apiPostCollect() {
    return request({
        url: "/api/first_reward/collect",
        method: "post",
    });
}
export function apiGetLossList(member_id) {
    return request({
        url: "/api/member/getfd?member_id="+member_id,
        method: "get",
    });
}
export function apiPostCollectLoss() {
    return request({
        url: "/api/member/addfd",
        method: "post",
    });
}
export function apiPerformance(data) {
    return request({
        url: "/api/agent/performance",
        method: "post",
        data
    });
}
export function apiSubTotal(data) {
    return request({
        url: "/api/agent/sub_total",
        method: "post",
        data
    });
}
export function apiSubbet(data) {
    return request({
        url: "/api/agent/sub_bet",
        method: "post",
        data
    });
}
export function apiGetGameInfo(params) {
    return request({
        url: "/api/game_info",
        method: "get",
        params
    });
}
export function apiGetOrderStatus(data) {
    return request({
        url: "/api/recharge/refresh_status",
        method: "post",
        data
    });
}
export function apiChangeLoginPassword(data) {
    return request({
        url: "/api/member/password/modify",
        method: "post",
        data
    });
}
export function apiText(data) {
    return request({
        url: "/api/test_tc",
        method: "post",
        data
    });
}
export function apiChangepwd(data) {
    return request({
        url: "/api/member/drawing_pwd/modify",
        method: "post",
        data
    });
}
