<template>
  <div class="header-wrap">
    <div  v-if="gameList.length > 0">
      <div class="apk-download" v-if="siteInfo.app_url && !hiddenDownload && !isiOS" :class="{'is-close':isClose}">
        <div class="row column-c"  @click="handleClose">
          <div class="close">
            <Icon  type="close-line"/>
          </div>
          <img class="apk-img" :src="siteInfo.uploads_pic" >
        </div>
        <pg-button type="primary" size="mini" @click="handleDownload">{{ $t('Downloaded') }}</pg-button>
      </div>
      <div class="header"  :class="{'is-close':isClose || !siteInfo.app_url || hiddenDownload || isiOS}">
        <div class="h-left">
        <span class="slider" :class="{ active: sliderShow }" @click="handleToggle">
          <Icon type="slider" />
        </span>
          <div class="log-wrap">
            <img class="logo" :src="siteInfo.logo" alt="">
          </div>
        </div>
        <div class="h-right">
          <div class="row">
            <div class="time" v-if="Layout.isPc">
            <span class="timebox">
              <span class="curtime">{{ currentTime }}</span>
              <span class="timezone">(UTC -03:00)</span>
            </span>
            </div>
            <pg-select class="pc-el" v-model="curLang" :options="langList" :bg="false"
                       @change="changeLanguage"></pg-select>
            <div class="userinfo" v-if="userInfo.id">
              <div class="money-wrap" v-if="inGame">
                <span class="icon-money"></span>
                <span class="in-game" key="inGame">{{ $t('In_game') }}</span>
              </div>
              <div class="money-wrap" v-else>
                <span class="icon-money"></span>
                <pg-price class="money" :price="money" @click="toWallet"/>
                <pg-refresh class="refresh" :toDo="refreshPrice" />
              </div>
              <Pg-button @click="showDesposit" class="pc-el" style="margin-left: 0.2rem;" type="primary">{{ $t('deposit')
                }}</Pg-button>
              <Pg-button class="pc-el" style="margin-left: 0.2rem;" :loading="getUserLoading"
                         @click="toPage(userInfo.has_qk_pwd ? '/withdrawal' : '/set-password')">{{ $t('withdraw') }}</Pg-button>
              <Pg-button class="pc-el" style="margin-left: 0.2rem;" @click="toPage('/offers?current=4&sub=0')">{{
                  $t('interest')
                }}</Pg-button>

              <div class="mobile-el">
                <div class="deposit-btn-wrap">
                  <div class="btn-left" @click="showDesposit">{{ $t('deposit') }}</div>
                  <div class="btn-right" v-clickoutside="handleOutClick" @click="toggleShow">
                    <Icon :class="{ active: depositDropdownVisible }" type="arrow-down" />
                  </div>
                  <transition name="el-zoom-in-top">
                    <ul class="dep-dropdown-list" v-show="depositDropdownVisible">
                      <li @click="toPage(userInfo.has_qk_pwd ? '/withdrawal' : '/set-password')">{{ $t('withdraw') }}</li>
                      <li @click="toPage('/offers?current=4')">{{ $t('interest') }}</li>
                    </ul>
                  </transition>
                </div>
              </div>
            </div>
          </div>
          <div class="pc-el" v-if="userInfo.id">
            <div class="info-container" @mouseenter="menuShow = true" @mouseleave="menuShow = false">
              <pg-avatar class="mr02"></pg-avatar>
              <div class="info-r">
                <div class="name">{{ userInfo.name }}</div>
                <div class="id">
                  <span>ID:{{ userInfo.id }}</span>
                  <span class="copy-wrap" @click="copy(userInfo.id)">
                  <Icon class="copy" type="copy"></Icon>
                </span>
                </div>
              </div>
              <div class="arrow-down">
                <Icon type="arrow-down" />
              </div>
              <transition name="slider-top">
                <div class="slider-down-container" v-show="menuShow">
                  <div class="s-main-content">

                    <div class="s-item" @click="toPage('/agent')">
                      <Icon type="agent" />
                      <span class="s-text">{{ $t('Agent') }}</span>
                    </div>
                    <div class="s-line">
                      <div class="line"></div>
                    </div>
                    <div class="s-item" @click="toPage('/report?current=0')">
                      <Icon type="statement" />
                      <span class="s-text">{{ $t('Statement') }}</span>
                    </div>
                    <div class="s-item" @click="toPage('/report?current=1')">
                      <Icon type="Bet_Records" />
                      <span class="s-text">{{ $t('Bet_Records') }}</span>
                    </div>
                    <div class="s-item" @click="toPage('/report?current=2')">
                      <Icon type="report" />
                      <span class="s-text">{{ $t('Reports') }}</span>
                    </div>
                    <div class="s-item" @click="toPage('/customer?current=2')">
                      <Icon type="message" />
                      <span class="s-text">{{ $t('Message_Center') }}</span>
                    </div>
                    <div class="s-line">
                      <div class="line"></div>
                    </div>
                    <div class="s-item" @click="toPage('/wallet')">
                      <Icon type="main_wallet" />
                      <span class="s-text">{{ $t('Main_Wallet') }}</span>
                    </div>
                    <div class="s-item" @click="toPage(userInfo.has_qk_pwd ? '/withdrawal' : '/set-password')">
                      <Icon type="withdrawal" />
                      <span class="s-text">{{ $t('Withdrawal_Management') }}</span>
                    </div>
                    <div class="s-item" @click="toPage('/profile')">
                      <Icon type="profile" />
                      <span class="s-text">{{ $t('Profile_me_info') }}</span>
                    </div>
                    <div class="s-item" @click="toPage('/security-center')">
                      <Icon type="safe" class="success-color" />
                      <span class="s-text">{{ $t('Security_Center') }}</span>
                    </div>
                    <div class="s-line">
                      <div class="line"></div>
                    </div>

                    <div class="s-item" @click="Layout.$refs.musicDialog.show()">
                      <Icon type="music" />
                      <span class="s-text">{{ $t('Music') }}</span>
                    </div>
                    <div class="s-item" @click="toPage('/customer?current=4')">
                      <Icon type="edit" />
                      <span class="s-text">{{ $t('Feedback_to_get_rewards') }}</span>
                    </div>
                    <div class="s-item" @click="loginOut">
                      <Icon type="loginout" class="loginout" />
                      <span class="s-text">{{ $t('Quit') }}</span>
                    </div>
                  </div>
                </div>
              </transition>

            </div>
          </div>
          <div v-else style="font-size: 0;" class="row column-c">
            <pg-button class="mr02 login-btn" type="primary" @click="toLogin(true)">{{ $t('login') }}</pg-button>
            <pg-button class="register-btn" @click="toLogin(false)">{{ $t('register') }}</pg-button>
          </div>

          <span class="icon-search mobile-el" @click="toPage('/search')">
          <Icon type="search" />
        </span>
        </div>
      </div>
    </div>
    <div class="header loding-wrap" v-else>
      <div class="h-left">

        <div class="loading-logo loading-animation"></div>
      </div>

      <div class="h-right ">
        <div class="block-1 loading-animation b-redius"></div>
        <div class="block-1 loading-animation b-redius"></div>
        <div class="block-1 loading-animation b-redius"></div>
        <div class="block-2">
          <div class="block-3 loading-animation b-redius"></div>
          <div class="column column-c ml01" v-if="Layout.isPc">
            <div class="line-1 loading-animation b-redius"></div>
            <div class="line-2 loading-animation b-redius"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {copyText, getLocalTime, isiOS} from '@/util'
import {LangList} from "@/config";
export default {
  data() {
    return {
      menuShow: false,
      depositDropdownVisible: false,
      curLang: localStorage.getItem('language') || 'por',
      langList: LangList,
      currentTime: "",
      inGame: false,
      isClose: false,
    }
  },
  inject: ['Layout'],
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      money: state => state.user.money,
      sliderShow: state => state.home.sliderShow,
      siteInfo: state => state.home.siteInfo,
      gameList: state => state.home.gameList,
      getUserLoading: state => state.user.getUserLoading
    }),
    isiOS(){
      return isiOS
    }
  },
  watch: {
    '$route': {
      handler(val) {
        this.inGame = val.path === '/game'
      },
      immediate: true
    }
  },
  mounted() {
    this.hiddenDownload = this.$route.query.resource === 'app'
    localStorage.setItem('isApp',this.hiddenDownload);
    this.updateCurrentTime();
    setInterval(() => {
      this.updateCurrentTime();
    }, 1000);
  },
  methods: {
    ...mapActions('user', ['GetUserMoney']),
    copy(text) {
      copyText(text)
    },
    handleClose(){
      this.isClose=true;
      this.$emit('close')
    },
    handleDownload() {
      const downloadUrl = this.siteInfo.app_url;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = new Date().getTime()+'.apk'; // 设置下载文件的名称
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleOutClick() {
      this.depositDropdownVisible = false
    },
    toPage(url) {
      this.$router.push(url)
    },
    toggleShow() {
      this.depositDropdownVisible = !this.depositDropdownVisible
    },
    refreshPrice() {
      this.$store.commit('user/SET_MONEY', 0)
      this.GetUserMoney()
    },
    showDesposit() {
      this.Layout.depositVisible = true
    },
    handleToggle() {
      this.$store.commit('SET_SLIDER_SHOW', !this.sliderShow)
    },
    toLogin(isLogin) {
      if (isLogin) {
        this.Layout.$refs.loginPop.type = 'login'
      } else {
        this.Layout.$refs.loginPop.type = 'register'
      }
      this.Layout.visible = true
    },
    loginOut() {
      this.Layout.loginOut = true;
    },
    toWallet() {
      if (this.Layout.isPc) {
        this.$router.push('/wallet')
      }
    },
    changeLanguage(lang) {
      localStorage.setItem('language', lang)
      this.$i18n.locale = lang;
      location.reload()
    },
    updateCurrentTime() {
      var date = getLocalTime(-3);
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours();
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${month}/${day}   ${hours}:${minutes}:${seconds}`;
    }
  },
}
</script>

<style lang='scss' scoped>
.header-wrap{
  .apk-download{
    height: 0.7rem;
    position: fixed;
    top: -1px;
    right: -1px;
    left: -1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #82ecff;
    z-index: 22;
    border: 1px solid var(--theme-color-line);
    overflow: hidden;
    transition: height .5s;
    padding-right: 0.2rem;
    .apk-img{
      display: block;
      width: 3.5rem;
    }
    .close{
      font-size: 0.2rem;
      height: 100%;
      padding: 0 0.3rem;
      color: rgb(97,154,243);
    }
    &.is-close{
      height: 0;
    }
  }

  .header {
    position: fixed;
    left: 0;
    top: 0.7rem;
    right: 0;
    height: 0.88rem;
    transition: top .5s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .2rem 0 .1rem;
    border-bottom: 1px solid var(--theme-color-line);
    z-index: 22;
    &:not(.loding-wrap){
      background-image: url("@/assets/images/bg_pattern_tile2.png");
      background-color: var(--theme-top-nav-bg);
    }
    &.is-close{
      top: 0;
    }
    &.loding-wrap {

      .loading-logo {
        height: 0.3rem;
        border-radius: 0.12rem;
        width: 2rem;
      }

      .block-1 {
        height: 0.34rem;
        width: 0.8rem;
        margin-right: 0.1rem;
      }

      .block-2 {
        display: flex;

        .block-3 {
          height: 0.4rem;
          width: 0.4rem
        }

        .line-1 {
          height: 0.1rem;
          width: 0.4rem;
        }

        .line-2 {
          height: 0.1rem;
          margin-top: 0.04rem;
          width: 2rem;
        }
      }

      .h-right {
        display: flex;
        justify-content: flex-end;
      }

    }

    .h-left {
      display: flex;
      align-items: center;

      .slider {
        font-size: .36rem;
        color: var(--theme-color-line, '#fff');
        transition: all .3s;

        &.active {
          transform: rotate(180deg);
        }
      }

      .log-wrap {
        max-width: 3.3rem;
        min-width: 2.1rem;
        height: 0.74rem;
        font-size: 0;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        .logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }


    }

    .h-right {
      display: flex;
      align-items: center;

      .pg-button {
        border-radius: 0.1rem;
        height: 0.5rem;
        line-height: 1;
        padding: 0 .1rem;
        font-size: .16rem;
        min-width: 1rem;
        max-width: 1.2rem;
        margin-right: .1rem;
        &.login-btn{
          background: var(--theme-alt-primary);
          color: var(--theme-primary-color);
        }
        &.register-btn{
          background: transparent;
          color: var(--theme-alt-primary);
          border-color: var(--theme-alt-primary);
        }

        &:nth-child(2) {}
      }

      .deposit-btn-wrap {
        margin-left: 0.1rem;
        border-radius: 0.1rem;
        font-size: .16rem;
        padding: 0;
        display: flex;
        align-items: center;
        color: var(--theme-primary-font-color);
        background: var(--theme-primary-color);
        position: relative;

        .dep-dropdown-list {
          position: absolute;
          background-color: var(--theme-main-bg-color);
          border-radius: 0.1rem;
          padding: 0.15rem 0;
          bottom: -0.05rem;
          left: 0;
          border: .01rem solid var(--theme-color-line);
          color: var(--theme-text-color-darken);
          font-size: .24rem;
          transform: translate(0%, 100%);
          box-shadow: 0 2px 8px rgba(0, 0, 0, .15);

          li {
            padding: 0 .19rem;
            height: 0.8rem;
            min-width: 1.3rem;
            line-height: .8rem;
          }
        }

        .btn-left {
          padding: 0 0.1rem;
          line-height: 0.4rem;
        }

        .btn-right {
          width: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .active {
            transform: rotate(-180deg);
          }

          &::after {
            background: var(--theme-primary-font-color);
            content: "";
            display: block;
            height: 0.24rem;
            left: 0;
            opacity: .3;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0.01rem;
          }
        }
      }

      .icon-search {
        font-size: .28rem;
        margin-left: .15rem;
        color: var(--theme-alt-primary);
      }

      .time {
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        margin-right: 0.2rem;

        .timebox {
          margin-left: 0;
          color: var(--theme-alt-border);
          display: inline-grid;
          font-size: .12rem;
          line-height: 1.5;
          min-width: 0.9rem;
          text-align: center;

          .curtime {
            color: var(--theme-alt-border);
            display: inline-block;
            font-size: .12rem;
            line-height: 1.5;
            margin-left: 0.77rem;
            min-width: 0.9rem;
            text-align: center;
          }

          .timezone {
            color: var(--theme-alt-border);
            display: inline-block;
            font-size: .12rem;
            line-height: 1.5;
            margin-left: 0.77rem;
            min-width: 0.9rem;
            text-align: center;
          }
        }
      }

      .userinfo {
        display: flex;
        align-items: center;

        .money-wrap {
          display: flex;
          align-items: center;
          height: 0.4rem;
          padding: 0 0.084rem 0 0.02rem;
          border: 0.01rem solid var(--theme-color-line);
          border-radius: 0.2rem;

          .money {
            margin: 0 0.073rem 0 0.1rem;
            max-width: 1.64rem;
            color: var(--theme-secondary-color-finance);
            font-size: .26rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .in-game {
            padding: 0 .13rem;
            font-size: 0.2rem;
            color: var(--theme-text-color-lighten);
          }

          .icon-money {

            display: inline-block;
            position: relative;
            width: 0.3rem;
            height: 0.3rem;
            background-image: var(--bgUrl);
            background-position: -4.96071rem -3.72857rem;
            background-size: 5.57143rem 5.15357rem;
          }

          .refresh {
            display: flex;
            align-items: center;
            color: var(--theme-primary-color);
            cursor: pointer;
            font-size: .293rem;
          }
        }


      }

      .info-container {
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 0.2rem;
        padding: 0.1rem;
        border-radius: 0.1rem;
        user-select: none;
        cursor: pointer;
        position: relative;

        .slider-down-container {
          position: absolute;
          bottom: 0;
          padding: .1rem 0;
          margin-left: -0.01rem;
          transform: translateY(100%);

          .s-main-content {
            padding: .01rem 0;
            box-sizing: border-box;
            border: 0.01rem solid var(--theme-color-line);
            margin-top: -0.045rem;
            max-width: 2.5rem !important;
            padding: 0.05rem 0;
            width: 2.1rem;
            background-color: var(--theme-main-bg-color);
            border-radius: 0.1rem;

            .s-item {
              font-size: .24rem;
              display: flex;
              align-items: center;
              height: 0.5rem;
              line-height: .5rem;
              padding: 0 0 0 0.17rem;
              background-color: var(--theme-main-bg-color);
              color: var(--theme-text-color);

              &:hover {
                background: var(--theme-bg-color);
              }

              .s-text {
                color: var(--theme-text-color);
                font-size: .15rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 81%;
                overflow: hidden;
                margin-left: 0.1rem;
              }
            }

            .s-line {
              flex-wrap: nowrap;
              align-items: center;
              display: -ms-flexbox;
              display: flex;
              height: 0.1rem;
              padding: 0 0.1rem;

              .line {
                background: var(--theme-color-line);
                height: 0.01rem;
                width: 100%;
              }
            }
          }
        }

        &:hover {
          background: var(--theme-main-bg-color);
        }

        .avatar {
          width: 0.56rem;
          border-radius: 0.1rem;
          height: 0.56rem;
          margin-right: 0.1rem;
          position: relative;
          background: #fff;

          .level-wrap {
            position: absolute;
            right: 0;
            bottom: 0;
            background: rgb(36, 178, 153);
            display: flex;
            align-items: center;
            height: 0.12rem;
            justify-content: center;
            max-width: 0.33rem;
            min-width: 0.29rem;
            border-radius: 0.04rem 0 0.04rem 0;

            .level-img {
              margin-top: 0.01rem;
              min-width: 0.163rem !important;
              width: 0.163rem;
            }

            .level-text {
              background: initial;
              font-size: .14rem;
              font-style: italic;
              margin: -.025rem 0 0 .01rem;
              transform: scale(.7);
              color: transparent;
              display: inline-block;
              font-weight: 700;
              text-shadow: 0 0.01rem 0 rgba(0, 0, 0, .4);

              &::before {
                background-clip: text;
                background-image: linear-gradient(180deg, #f7ea94 0, #e5b952 51%, #ce9510);
                background-size: cover;
                content: attr(data-text);
                height: 100%;
                left: 0;
                position: absolute;
                text-shadow: none;
                top: 0;
                width: 100%;
                z-index: 0;
              }
            }
          }
        }

        .info-r {
          .name {
            color: var(--theme-text-color-darken);
            font-size: .18rem;
          }

          .id {
            min-width: 1rem;
            display: flex;
            justify-content: space-between;
            padding-top: 0.06rem;
            font-size: .18rem;
            color: var(--theme-alt-border);
            margin-right: 0.1rem;

            .copy-wrap {
              font-size: .2rem;
              margin-left: 0.1rem;
              color: var(--theme-primary-color);
            }
          }
        }

        .arrow-down {
          margin-left: 0.1rem;
          font-size: .1rem;
          color: var(--theme-text-color-lighten);
        }
      }

    }

  }

  .pc-page {
    .header {
      height: 0.8rem;
      justify-content: flex-start;
      z-index: 100;

      .h-left {
        .log-wrap {
          max-width: 2.2rem;
          min-width: 2.2rem;
          width: 2.2rem;
        }

        .slider {
          font-size: .263rem;
          margin: 0px 0.25rem 0px 0.08rem;
        }
      }

      ::v-deep .h-right {
        flex: 1;
        display: flex;
        justify-content: space-between;
        max-width: calc(50vw + 4.4rem);

        .pg-select-wrap {
          margin-right: .2rem;

        }

        .userinfo {


          .money-wrap {
            .refresh {
              font-size: .22rem !important;
            }

            .money {
              cursor: pointer;
              font-size: .22rem !important;
              text-decoration: underline;
            }
          }
        }
      }
    }


  }
}
</style>
