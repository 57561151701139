export default {
    Home: "Home",
    offers: "offers",
    Profile: "Profile",
    Profile_me_info: "Profile",
    Withdrawal: "Withdrawal",
    Customer_service: "Customer service",
    login: "Login",
    register: "Register",
    deposit: "Deposit",
    withdraw: "Withdraw",
    Withdraw_Records: "Withdraw Records",
    Audit_Report: "Audit Report",
    Manage_Account: "Manage Account",
    Balance: "Balance",
    Not_Started: "Not Started",
    Completed: "Completed",
    Ongoing: "Ongoing",
    interest: "Interest",
    dont_show: "Don't show again today",
    Casino: "Casino",
    Reward: "Reward",
    Agent: "Agent",
    tab_agent: "Agent",
    net_info: "Agent Network",
    Offer_Center: "Offer Center",
    Event: "Event",
    APP_download: "APP download",
    Mission: "Mission",
    Games: "Games",
    Support: "Support",
    Online_Service: "Online Service",
    Help_Center: "Help Center",
    Message_Center: "Message Center",
    forgot_password: "Forgot Password",
    Remmeber_Me: "Remmeber Me",
    Register_An_Account: "Register An Account",
    Username: "Username",
    Name: "Name",
    user_tip: "4-16 bit character, support English/numbers",
    password: "Password",
    password_tip: "6-16 characters",
    password_agin_tip: "The passwords do not match. Please re-enter.",
    Re_enter_password: "Re-enter password",
    over_tip: "I am over 18 years old, have read, and agree to the",
    User_Agreement: "User Agreement",
    I_have_read: "I have read",
    user_agreement_1: `1. In order to avoid wager disputes, members must read the company rules before entering the Applicable. Once the player "I agree" By entering this company to bet, it will be considering that you agree with the company's User Agreement. `,
    user_agreement_2: `2. It is the responsibility of the member to ensure the confidentiality of his account and information of login. Any online bets made with your account number and member password will be considered valid. Please change your password from time to time. The company doesn't know liability for any compensation of bets made on account and stolen password.`,
    user_agreement_3: `The company reserves the right to amend this agreement or the rules of the game or the rules of the confidentiality of time in time. The amended terms shall enter into force on the date specified after the change occurs, and the right to take final decisions on all disputes are reserved for the company.`,
    user_agreement_4: `4. Users must be older in accordance with the laws of the country of residence to use casino or online app. Online bets that have not been successfully submitted will be considered null.`,
    user_agreement_5: `5. When a player is disconnected automatically or forcibly from the game before the result of the game is disclosed, this will not affect the result of the game.`,
    Messages: "Messages",
    Account: "Account",
    Main_Wallet: "Main Wallet",
    To_achieve_next_level: "To achieve next level",
    Betting_still_lacks: "Betting still lacks",
    Upgrade_Needs_Wager: "Upgrade Needs Wager",
    Upgrade_Needs_recharge: "Upgrade needs recharge",
    Account_Details: "Account Details",
    Bet_Records: "Bet Records",
    Report: "Report",
    Reports: "Reports",
    Withdrawal_Management: "Withdrawal Management",
    Withdrawal_Method: "Withdrawal Method",
    million_monthly: "million monthly",
    Security_Center: "Security Center",
    Language: "Language",
    Feedback_to_get_rewards: "Feedback to get rewards",
    Quit: "Quit",
    Login_Now: "Login Now",
    First_deposit_upon_registration: "First deposit upon registration",
    Bonus: "Bonus",
    Collected: "Collected",
    Task_time: "Task time",
    bonus_1: "Long-term task (each new account can only participate once)",
    bonus_2: "II.Task conditions:",
    bonus_2_content: "Complete account-related settings and security linkings",
    bonus_3: "III.Task content:",
    bonus_3_content: `1.Every new registered account can complete the above tasks, and upon
  completion,
  will receive a certain amount of bonus. The higher the difficulty, the greater the reward.<br>2.As
  each
  account is entirely anonymous, once stolen, resulting in a loss of funds, it will be unrecoverable.
  Therefore, we mandate linking two-step verification, especially when adding withdrawal addresses, to
  prove
  it's a personal operaten and ensure your security.<br>3.Conditions fulfilled to claim directly, and
  can be
  claimed directly on any platform; they will expire if not claimed (failure to claim is considered
  voluntary forfeiture).<br>4.Due to the relatively high bonus for this task, the bonus requires 2 times
  audit (i.e., audit, wagering, or valid bets) for withdrawal, without restrictions on game
  platforms.<br>5.This task is exclusively for the account holder's normal manual operatens; renting,
  using
  cheats, bots, cross-account gambling, mutual brushing, arbitrage, API, protocols, exploiting
  vulnerabilities, group controls, or other technical means are prohibited. Violations will result in
  the
  cancellation or deduction of rewards, account freezing, or even placement in a blacklist.<br>6.In
  order to
  avoid differences in text understanding, the platform will retain the final interpretation of this
  event.`,
    Accumulated_bets_made: "Accumulated bets made",
    Go: "Go",
    Deposit_Records: "Deposit Records",
    Online_Deposit: "Online Deposit",
    Deposit_Amount: "Deposit Amount",
    Bonus_event_explanation: "Bonus event explanation",
    Deposit_Now: "Deposit Now",
    The_first_deposit_to_receive: "The first deposit to receive",
    Total_Deposit: "Total Deposit",
    Deposit_Details: "Deposit Details",
    Today: "Today",
    Yesterdaty: "Yesterdaty",
    Days: "Days",
    Day: "Day",
    DAY: "DAY",
    All: "All",
    Total_Withdrawal: "Total Withdrawal",
    Total_Withdraw: "Total Withdraw",
    Accumulated_Claimed_Benefits: "Accumulated Claimed Benefits",
    Transaction_Type: "Transaction Type",
    Action: "Action",
    Deposit_Method: "Deposit Method",
    Deposit_channels: "Deposit channels",
    Time_Created: "Time Created",
    Order_No: "Order No",
    Newbie_Bonus: "Newbie Bonus",
    Daily_Mission: "Daily Mission",
    Claim_All: "Claim All",
    History: "History",
    Rebate: "Rebate",
    Claim: "Claim",
    Claim_1: "Claim",
    Claimed: "Claimed",
    Next: "Next",
    No_Records: "No Records",
    Level: "Level",
    Valid_Bets: "Valid Bets",
    First_Deposit: "First Deposit",
    Online_login_and_counts: 'Offline',
    Online_login_and_counts_pc: "Online login and counts",
    Commission: "Commission",
    Promotion_Sharing: "Promotion Sharing",
    My_Data: "My Data",
    Agent_Network: "Agent Network",
    Referral_Link: "Referral Link",
    Performance: "Performance",
    Subordinate_s_Wagers: "Subordinate's Wagers",
    All_data: "All data",
    Subordinate_stats: "Subordinate's Stats",
    Agent_Bonus_Rate: "Agent Bonus Rate",
    Collectable: "Collectable",
    Agent_Tier: "Agent Tier",
    Promotion_conditions: "Promotion conditions (Accumulative Performance)",
    Total_Pending_Audit: "Total Pending Audit",
    Audit_Status: "Audit Status",
    Funds_Change: "Funds Change",
    Number_of_audits: "Number of audits",
    Total_Audit_Required: "Total Audit Required",
    Pending_audit: "Pending audit",
    Audited: "Audited",
    Limited_to_the_platform: "Limited to the platform",
    Game_Limited: "Game(s) Limited",
    Total_Bet_Lucky_Spin: "Total Bet Lucky Spin",
    My_Link: "My Link",
    Quick_Share: "Quick Share",
    valid_s: "Valid subordinates",
    people: " people",
    Details: "Details",
    box_tip: "What is a valid number of referrals? (Simultaneously meeting the following conditions)",
    the_s: "The subordinate's total deposit",
    the_turnover: "The subordinate's total turnover",
    Or_the_above: "Or the above",
    Create: "Create",
    Mine: "Mine",
    Feedback_Content: "Feedback Content",
    Suggestions_for_revision: "Suggestions for revision",
    Attachment: "Attachment",
    Easier_to_be_adopted: "Easier to be adopted",
    Reupload: "Reupload",
    upload_tip: "Supports image and video format, size should not exceed 50MB",
    Reward_Rules: "Reward Rules",
    Reward_Rules_content: "We have set a huge bonus to collect feedback specifically to optimize our system and features for a better experience for you! Once adopted, rewards will be given based on the importance (excluding non-adopted ones).",
    Submit_feedback: "Submit feedback",
    No_Messages: "No Messages",
    online_tip: "Professional full-time customer service is always online to help you with any questions.",
    plat_sup: "Support",
    News: "News",
    Notification: "Notification",
    Marquee: "Marquee",
    daily_i_1: "Continuously signed in for",
    daily_i_2: "days",
    Required_deposit: " Required deposit ",
    Required_bets: "Required bets ",
    daily_rule_title_1: "I.Event Requirement:",
    daily_rule_content_1: "Deposit + Wagering (resets after 7 consecutive days)",
    daily_rule_title_2: "II.Event content:",
    daily_rule_content_2: `1.Daily deposit and wagering that meet the event conditions are required for successful check-in to
  claim<br>
  corresponding fixed bonuses or mystery bonuses, up to 19.<br>
  2.This event is a consecutive check-in event (resumes from day 1 in case of interruptions);<br>
  3.Rewards can only be manually claimed in iOS、Android、H5、PC<br>
  4.The bonus given in this event (excluding the principal) requires 1 times rollover (i.e., verification,
  wagering, or valid betting) for withdrawal, wagering is not limited to a game platform;<br>
  5.This event is only for the account owner's normal manual operatens, renting, using cheating software,
  bots,<br>
  gambling between different accounts, mutual manipulation, arbitrage, APIs, protocols, exploiting
  vulnerabilities, group control, or other technical means are prohibited, otherwise rewards may be canceled
  or<br>
  deducted, or accounts may be frozen or even blacklisted;<br>
  6.In order to avoid differences in text understanding, the platform will retain the final interpretation
  of<br>
  this event.`,
    Back: "Back",
    Hot: "Hot",
    Recent: "Recent",
    Distributed: "Distributed",
    Deposited: "Deposited",
    Transfer_in: "Transfer in",
    Transfer_out: "Transfer out",
    Settlement_period: "Settlement period",
    hours: "hours",
    Total_claimed: "Total claimed",
    Interest_ceiling: "Interest ceiling",
    No_restrictions: "No restrictions",
    Total_Earnings: "Total Earnings",
    Record_details: "Record details",
    Interest_rules: "Interest rules",
    Interest_tips: "Annual Percentage Rate 15%",
    Time: "Time",
    Type: "Type",
    Amount: 'Amount',
    Rebate_Records: 'Rebate<br>Records',
    Rebate_rate: "Rebate rate",
    Receive_amount: "Receive amount",
    Rebateable: "Rebateable",
    to_level: "To achieve next level",
    bsl: "Betting still lacks",
    bdl: "The recharge is still short of 10",
    VIP_Level_List: "VIP Level List",
    Promotion_Bonus: "Promotion Bonus",
    Weekly_Bonus: 'Weekly Bonus',
    Monthly_Bonus: 'Monthly Bonus',
    Tips: "Tips",
    tips_content: `To advance to the next level, based on the existing cumulative valid bets, additional
  bets are required. For
  instance, to upgrade to VIP 1, you need 1000 cumulative valid bets, and to upgrade to VIP 2, you need 2000
  cumulative valid bets. Therefore, to reach VIP 2, a player needs a total of 3000 accumulative valid bets (1000 +
  2000), and so on.`,
    vip_rule_title: "VIP Rules Explanation",
    vip_rule: `1.Promotion standard: Meet the VIP promotion requirements (i.e., both deposit or valid bets meet the criteria) to upgrade to the corresponding VIP level and receive the corresponding promotion bonus. If you consecutively upgrade multiple levels, you can receive all the level promotion bonuses.Real-time bonuses can be claimed;\r\n
  2.Daily Bonus: By meeting the current level's daily deposit and valid betting requirements, you can receive the corresponding daily bonus. If consecutively advancing across multiple levels, only the current level's daily bonus can be obtained.Real-time bonuses can be claimed;\r\n
  3.Weekly Bonus: By meeting the deposit and valid betting requirements for the current level each week, one can receive the corresponding weekly bonus. If consecutively advancing across multiple levels, only the weekly bonus of the current level can be obtained.Real-time bonuses can be claimed;\r\n
  4.Monthly Bonus: By meeting the deposit and valid betting requirements for the current level each month, one can receive the corresponding monthly salary bonus. If consecutively advancing across multiple levels, only the monthly bonus of the current level can be obtained.Real-time bonuses can be claimed;\r\n
  5.Reward expiraten time: The received bonus is retained for %d days. If not claimed actively during this period, it will be automatically credited to the account. For example: if a reward is obtained on January 1st and retained for %d days, it will be automatically credited to the account on January %d at 00:00:00.\r\n
  6.Maintenance Explanation: After reaching a certain VIP level, if a member's deposit and valid bets from the previous month fail to meet the maintenance requirements, they will be automatically demoted by one level.\r\n
  7.Audit Note: VIP bonuses need to be wagered 1 times (i.e., audited, played through, or through valid bets) to be eligible for withdrawal, Wagering is not limited to any game platform;\r\n
  8.Event Statement: This feature is only for the account owner's normal gaming betting. Renting accounts, risk-free betting (matching, brushing, low odds), malicious arbitrage, using plugins, bots, exploiting agreements, vulnerabilities, interfaces, group control, or other technical means are prohibited. Once verified, the platform reserves the right to terminate member logins, suspend member website usage, and confiscate bonuses and improper profits without prior notice.\r\n
  9.Explanation: By claiming VIP rewards, the platform assumes that the member agrees to comply with the corresponding conditions and related regulations. To avoid misunderstandings in text interpretation, the platform reserves the final right to interpret this event.`,
    Misson: "Misson",
    Set_Up: "Set Up",
    Link: "Link",
    Please_link_your_mobile_phone: "Please link your mobile phone",
    Please_link_your_mail: "Please link your email",
    birth_tip: "Birthday (cannot be modified once set)",
    pltwaf: "Please link the Withdrawal account first",
    ped: 'Please enter 6-40 digits',
    plw: 'Please enter WhatsApp',
    pefa: "Please enter Facebook account",
    pet: 'Please enter Telegram',
    eut: 'Enter up to 5-40 ',
    Year: "Year",
    Save: "Save",
    Save_Btn: "SAVE",
    Male: "Male",
    Female: "Female",
    Statement: "Statement",
    Cumulative_bet_amount: "Cumulative bet amount",
    Total_Valid_Bets: "Total Valid Bets",
    Total: "Total W/L",
    nob: "No. of Bet",
    ba: "Bet amt.",
    Ganhos: "W/L",
    Verify_Login_Password: "Verify Login Password",
    you_tip: "You can link the phone after verifying the login password",
    Other_Verification_Methods: "Other Verification Methods",
    The_above_method_is_not_available: "The above method is not available?",
    Contact_Us: "Contact Us",
    Enter_Password: "Enter Password",
    Mobile_Phone: "Mobile Phone",
    phone: "Please enter phone number",
    Player_ID: "Player ID",
    Unlinked: "Unlinked",
    Email: "Email",
    Login_Password: "Login Password",
    Withdrawal_Password: "Withdrawal Password",
    Security_Question: "Security Question",
    Link_Third_party_Login: "Link Third-party Login",
    Unset: "Unset",
    set_title: "It's your first withdrawal, you need to set a withdrawal password first",
    set_up: "Set Up Withdrawal Password",
    New_Withdrawal: "New Withdrawal Password",
    Old_Withdrawal: "Old Withdrawal Password",
    password_note: `Note: Your withdrawal password protects the security of your funds. It is very important and
    should only be known to yourself to avoid any financial loss.`,
    confirm: "confirm",
    Confirm: "Confirm",
    Current_Lucky_Value: "Current Lucky Value",
    you_still_need: "You still need to wager",
    to_get: "to get",
    Lucky_Points: "Lucky Points",
    Instant: " Instant<br>Draw",
    Luck_Value: "Luck Value",
    Silver: "Silver",
    Golden: "Golden",
    Diamond: "Diamond",
    Reward_announcement: "Reward announcement",
    My_Records: 'My Records',
    won_at: "won at",
    returntable_rule_content: "Accumulated valid bets (i.e., specified platform's bets or audit)",
    Designated_platform: "Designated platform",
    return_table_content_2: `1.During the event period, every 1 valid bet automatically earns 1 lucky point. 1000-10000 lucky
    points allow one draw, with the maximum reward being 9999.<br>
    2.Lucky points obtained today and unused by the next day will expire and reset to zero;<br>
    3.Rewards can only be manually claimed in iOS、Android、H5、PC<br>
    4.The bonus given in this event (excluding the principal) requires 1 times rollover (i.e., verification,
    wagering, or valid betting) for withdrawal, wagering is not limited to a game platform;<br>
    5.This event is only for the account owner's normal manual operatens, renting, using cheating software,
    bots, gambling between different accounts, mutual manipulation, arbitrage, APIs, protocols, exploiting
    vulnerabilities, group control, or other technical means are prohibited, otherwise rewards may be
    canceled or deducted, or accounts may be frozen or even blacklisted;<br>
    6.In order to avoid differences in text understanding, the platform will retain the final interpretation
    of this event.`,
    twde: "Total wagers during the event period",
    Used_Lucky_Value: "Used Lucky Value",
    Expired_Lucky_Value: "Expired Lucky Value",
    Available_Lucky_Value: "Available Lucky Value",
    Claim_Successfully: "Claim Successfully",
    Total_Account_Balance: "Total Account Balance",
    One_click_transfer: "One click transfer",
    Live: "Live",
    Fishing: "Fishing",
    Sports: "Sports",
    Cards: "Cards",
    Lottery: "Lottery",
    Blockchain: "Blockchain Games",
    Slots: "Slot",
    All_Type: "All Type",
    ALL_Platform: "All Platform",
    Platform: "Platform",
    Total_Commission: "Total Commission",
    Referral_information: "Referral information",
    More: "More",
    Commission_Collected: "Commission Collected",
    Last_Commission: "Last Commission",
    Total_Members: "Total Members",
    Total_angent: "Total",
    Direct_Subordinates: "Direct Subordinates",
    Others: "Others",
    Total_Perf: "Total Perf",
    Sub_Perf: "Sub's Perf",
    Others_Perf: "Others' Perf",
    Subordinate_Wagers: "Subordinate's Wagers",
    Total_valid_bets: "Total valid bets",
    total_no: "Total no. of bet",
    Total_angent_wl: "Total W/L",
    Withdrawal_Account: "Withdrawal Account",
    Hide: "Hide",
    Show: "Show",
    Add_New_Bank_Account: "Add New Bank Account",
    Enter_PIN: "Enter PIN",
    for_you_account: "For your account safety, please enter the withdrawal password",
    pix_tip: "PIX Account cannot be empty",
    digits_11: "Please enter 11 digits",
    digits_6: "Please enter 6 digits",
    Set_as_Default: "Set as Default",
    Add_withdrawal_account: "Add withdrawal account",
    add_account_tip: "Please select a withdrawal account first",
    with_tip_1: "The remaining wagering amount required for withdrawal is",
    with_tip_2: " ",
    Normal_withdrawal: "Normal withdrawal",
    Min: 'Min',
    Max: 'Max',
    Withdrawal_Amount_cannot_be_empty: 'Withdrawal Amount cannot be empty',
    withdraw_err: "Withdrawal not available until the audit is completed",
    wp_can_be_empty: "Withdrawal Password cannot be empty",
    Can_be_collected_today: "Can be collected today",
    Fee: "Fee",
    Add: "Add",
    All_status: "All status",
    Not_settled: 'Not settled',
    Settled: 'Settled',
    Order_canceled: "Order canceled",
    Confirm_New_Withdrawal_Password: "Confirm New Withdrawal Password",
    Search: "Search",
    Search_Games: "Search Games",
    tab_Search: "Search",
    Favorite: "Favorite",
    Reports_tab: "Report",
    em_claim: "Currently no available rewards to claim",
    Start_Date: "Start Date",
    End_Date: "End Date",
    Cancel: "Cancel",
    My_ID: "My ID",
    Contribution_Commission: "Contribution Commission",
    total_first: "Total first-deposit players",
    Other_first_deposit: "Other first deposit",
    Other_deposit: "Other deposit",
    Direct_first_deposit: "Direct first deposit",
    Subordinate_Deposits: "Subordinate Deposits",
    Subordinate: "Subordinate",
    Full_Screen: "Full Screen",
    Refresh: "Refresh",
    Yes: "Yes",
    No: "No",
    Settlement_date: "Settlement date",
    Contributors: "Contributors",
    Joining_time: "Joining time",
    Member_ID: "Member ID",
    Statistical_date: "Statistical date",
    Number_of_bets: "Number of bets",
    Total_W_L: "Total W/L",
    bounusTip: "Downline members will only contribute rebates if their deposits ≥0 and have made valid bets ≥0 during the settlement period.",
    Type_Details: "Type Details",
    Bet_ID: "Bet ID",
    Bet_amount: "Bet amount",
    In_game: "In-game use",
    page: 'page',
    Page: 'Page',
    entries: 'entries',
    page_total_1: "Total of",
    page_total_2: "entries",
    more_1: "Currently displaying",
    more_2: "Hot games out of",
    more_3: "",
    Load_More: "Load More",
    Reminder: "Reminder",
    Log_out_from_account: "Log out from account",
    Cashback_Exclusivo: "Cashback Exclusivo",
    privileges_Exclusivo: "VIP privileges",
    login_password_tip: 'Modify the login password after verifying the login password',
    Confirm_the_new_password: "Confirm the new password",
    Daily_loss_rescue_fund: "Daily loss rescue fund",
    Loss_amount: "Loss amount",
    Extra_Rewards: "Extra Rewards",
    Yesterday_s_losses: "Yesterday's losses",
    Today_s_Rescue_Bonus: "Today's Rescue Bonus",
    Lucky_wager: "Lucky wager",
    No_winning_wager_today: "No winning wager today",
    max_times_claimed: "Max. {num} times claimed today ({num} times left)",
    Bet_number_last_digits: "Bet number last digits",
    Reward_multiple: "Reward multiple",
    Reward_limit: "Reward limit",
    reward_amount: "Reward amount = Bet amount of that bet slip x Bonus multiplier, without exceeding the limit",
    Music: 'Music',
    Cycle: 'Cycle',
    Shuffle: 'Shuffle',
    Repeat: 'Repeat',
    Downloaded: 'Downloaded',
    System_Music: "System Music",
    My_music: "My music",
    change_password_note: "Attention: The withdrawal password protects your funds and is extremely important. Keep it to yourself to prevent any financial loss",
    Change_Withdrawal_Password: 'Change Withdrawal Password',
    Link_Email: 'Link Email',
    Enter_Email: 'Enter Email',
    Deslize_para: 'Deslize para concluir o puzzle',
    length_tip: '{lengthRange}dígitos',
    Numbers: 'Numbers',
    Lowercase: 'Lowercase',
    Uppercase: 'Uppercase',
    My_superiors: 'My superiors',
    Agent_mode: 'Agent mode',
    Unlimited_level: 'Unlimited level difference (Daily settlement)',
    Me_Header_user: "Data",
    Me_Header_Support: "Support",
    Yesterday: "Yesterday",
    This_Week: "This Week",
    Last_Week: "Last Week",
    This_Month: "This Month",
    Last_Month: "Last Month",
    Add_direct: "Add direct members",
    deposit_players: "Number of first deposit players",
    Number_deposits: "Number of deposits",
    Deposit_amount: "Deposit amount",
    Direct_subordinates: "Direct subordinates",
    RealName: "real name",
    real_name_validate:'4 - 16 character bits, support in English/numbers',
    is_required:'is required',

    // 399pop
    vip_level:'VIP level',
    Total_recharge:'Total recharge',
    Withdrawal_limit:'Withdrawal limit',
    Current_VIP_level:'Current VIP level',
    Available_withdrawal_limit:'Available withdrawal limit',
    No_limit: "Unlimited"
};

