import popComponent from "./pop.vue";
import Vue from "vue";
import i18n from "@/lang";


const popConstructor = Vue.extend(popComponent);
const instances = [];
let id = -1;
function pop(options) {
  const popInstance = new popConstructor({
    i18n
  });
  popInstance.requestResult = options.requestResult;
  popInstance.$mount(document.createElement("div"));
  document.body.appendChild(popInstance.$el);
  id++;
  popInstance.id = id;
  popInstance.show();
};

export default pop;
