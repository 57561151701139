<template>
  <transition name="slide" @after-leave="destoryElement">
    <div class='message-wrap' :class="[{ success: type === 'success' },
    { warning: type === 'warning' },
    { error: type === 'error' },
    { info: type === 'info' },]" :style="{ top: top + 'px' }" v-show="visible">
<!--      <Icon v-if="type === 'success'" type="success" />-->
      <span class="msg">{{ message }}</span>
    </div>
  </transition>
  
</template>

<script>

export default {
    data() {
        return {
            visible: false,
            top: 20
        };
    },
    props: {
        message: {
            type: String,
        },
        type: {
            type: String,
            default: 'info'
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        destoryElement() {
            this.$destroy(true);
            this.$el.remove();
        }
    },
}
</script>

<style lang='scss' scoped>
@keyframes slide-top {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.slide-enter-active {
  animation: slide-top .3s;
}

.slide-leave-active {
  animation: slide-top .3s;
}

.message-wrap {
  position: fixed;
  left: 50%;
  z-index: 999;
  padding: 0.2rem 0.4rem;
  font-size: 0.3rem;
  transform: translateX(-50%);
  white-space: wrap;
  transition: top .2s;
  display: flex;
  max-width: 60vw;
  word-break: break-all;
  align-items: center;
  justify-content: center;
  border-radius:0.2rem;
  .msg{
    margin-left: 0.1rem;
  }
  &.success {
    background: mix(#ffffff, #67C23A, 90%);
    color: #67C23A;
  }

  &.warning {
    background: mix(#ffffff, #E6A23C, 90%);
    color: #E6A23C;
  }

  &.error {
    background: mix(#ffffff, #F56C6C, 90%);
    color: #F56C6C;
  }

  &.info {
    background: mix(#ffffff, #909399, 90%);
    color: #909399;
  }
}
</style>