import i8n from '@/lang/index'
export const gameTypes = [
  { label: i8n.t('Live'), value: 1 },
  { label: i8n.t('Fishing'), value: 2 },
  { label: i8n.t('Slots'), value: 3 },
  { label: i8n.t('Lottery'), value: 4 },
  { label: i8n.t('Sports'), value: 5 },
  { label: i8n.t('Cards'), value: 6 },
  { label: i8n.t("Blockchain"), value: 8 },
];

export const levelList = [
  { img: require("@/assets/images/img_tpdl.png"), level: 1, score: "0,00" },
  { img: require("@/assets/images/img_tpdl2.png"), level: 2, score: "100,00" },
  { img: require("@/assets/images/img_ypdl.png"), level: 3, score: "300,00" },
  { img: require("@/assets/images/img_ypdl2.png"), level: 4, score: "500,00" },
  { img: require("@/assets/images/img_jpdl.png"), level: 5, score: "800,00" },
  { img: require("@/assets/images/img_jpdl2.png"), level: 6, score: "1.000,00" },
  { img: require("@/assets/images/img_zsdl.png"), level: 7, score: "10.000,00" },
  { img: require("@/assets/images/img_zsdl2.png"), level: 8, score: "30.000,00" },
  { img: require("@/assets/images/img_cjzd.png"), level: 9, score: "50.000,00" },
  { img: require("@/assets/images/img_cjzd2.png"), level: 10, score: "80.000,00" },
  { img: require("@/assets/images/img_cjzj.png"), level: 11, score: "100.000,00" },
  { img: require("@/assets/images/img_cjzj2.png"), level: 12, score: "1.000.000,00" },
  { img: require("@/assets/images/img_hhgd.png"), level: 13, score: "3.000.000,00" },
  { img: require("@/assets/images/img_hhgd2.png"), level: 14, score: "5.000.000,00" },
  { img: require("@/assets/images/img_cjgd.png"), level: 15, score: "8.000.000,00" },
  { img: require("@/assets/images/img_cjgd2.png"), level: 16, score: "10.000.000,00" },
  { img: require("@/assets/images/img_jtds.png"), level: 17, score: "100.000.000,00" },
  { img: require("@/assets/images/img_jtds2.png"), level: 18, score: "300.000.000,00" },
  { img: require("@/assets/images/img_jtgd.png"), level: 19, score: "500.000.000,00" },
  { img: require("@/assets/images/img_jtgd2.png"), level: 20, score: "800.000.000,00" },
];
export const LangList = [
  { label: 'Português', value: 'por' },
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: 'Bahasa', value: 'bl' },
  { label: 'Türkçe', value: 'tl' },
]

export const moneyUnit = '$';
